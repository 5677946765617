import {
  Sex,
  InsuranceAddress,
  InsurancePayer,
  PreferredLanguage,
  InsuranceProvider,
} from '~/types'
import { useMutation } from '~/utils/useMutation'
import { useAuth0 } from '@auth0/auth0-react'
import { ValueType } from 'react-select'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { storedToken, parseAPIDate, useSetToken } from '~/utils'
import api from '~/api'
import { useLocation } from 'react-router'
import { format, parse } from 'date-fns'
import { logEvent } from '~/utils/events'
import { employerField } from '~/constants/SignUp'
import { secureCache } from '~/utils/secureCache'

export interface KeyValueEntry<L = string, V = string> {
  label: L
  value: V
}
export type LabelValueType = ValueType<KeyValueEntry>

export interface PreferredLanguageEntry extends KeyValueEntry<string, string> {
  name: string
}

export interface NewPatientNameDate {
  firstName: string
  lastName: string
  preferredName: string
  consentForms: number[]
  termsAndConditions: boolean
}

export interface NewPatientMemeberIdData {
  memberId: string
}
export interface NewPatientData extends NewPatientNameDate {
  phoneNumber: string
  sex: any | null
  gender: any | null
  pronouns: any | null
  dob: string
}

export interface PartnerPatientData extends NewPatientData {
  insuranceInfo: {
    addresses: InsuranceAddress[]
    memberId: string
  }
}

export interface NewPatientPayload extends NewPatientData {
  createdFrom: string
  lastSsn?: string | null
  // eslint-disable-next-line no-use-before-define
  state: StateItemsProps
  consentForms: number[]
  insuranceMemberInfo: {
    state: string
    memberId?: string | null
    insurancePayer?: InsurancePayer | null
  }
  insurancePayer?: InsurancePayer | null
  sourceType?: { label: string; value: string } | null
  preferredLanguage?: number | null
  preferredLanguageOther?: string | null
  employeeIdentifier?: string | null
  nextScreen?: string | null
  additionalInfoType?: string | null
}
export interface MemberIdConfirmationPayLoad {
  firstName: string
  lastName: string
  phoneNumber?: string
  preferredName?: string
  employeeIdentifier?: string
  sex: any | null
  gender: any | null
  pronouns: any | null
  dob: string
  createdFrom: string
  patientReferralProgram?: string
  preferredLanguage: Number | null
  preferredLanguageOther?: string | null
  insuranceMemberInfo: {
    state: string
    sourceType?: string
    insurancePayerId: Number
    insurancePayerName?: string | null
  }
  consentForms?: number[]
}

export interface InsuranceTypeFormState {
  sourceType: { label: string; value: string; subText: string }
}

export interface InsurancePayerFormState {
  insurancePayer: any
}

export interface NewPatientFormState extends NewPatientData {
  insurancePayer: any
  sourceType: { label: string; value: string } | null
  state: any
  preferredLanguage?: PreferredLanguageEntry | null
  preferredLanguageOther?: string
  employeeIdentifier?: string
  employer?: string
}

export interface NewPatientConfirmationState {
  lastSsn?: string | null
  employeeIdentifier?: string | null
}

export const usePronouns = () =>
  useQuery('pronouns', () => api.rest.get<any[]>(`/facts/pronouns/?is_deleted=false`), {
    staleTime: Infinity,
    placeholderData: [],
    select: data =>
      data?.map(s =>
        Object.assign(s, {
          label: s.label,
          value: s.label,
        })
      ),
  })

export const useGenderIdentities = () =>
  useQuery(
    'genderIdentities',
    () => api.rest.get<any[]>(`/facts/gender-identities/?is_deleted=false`),
    {
      staleTime: Infinity,
      placeholderData: [],
      select: data =>
        data?.map(s =>
          Object.assign(s, {
            label: s.label,
            value: s.label,
          })
        ),
    }
  )

export const usePartnerData = (signature: string | null) =>
  useQuery(
    'partnerData',
    () => api.rest.get<PartnerPatientData>(`/user/partner?signature=${signature}`),
    {
      staleTime: Infinity,
      enabled: Boolean(signature),
      select: data =>
        Object.assign(data, {
          dob: data.dob ? parseAPIDate(data.dob) : '',
        }),
    }
  )

export interface StateItemsProps {
  name: string
  abbreviation: string
  canServiceBenefit: boolean
  canService: boolean
  label: string
  value: string
}
export const useStates = () =>
  useQuery<StateItemsProps[]>('states', () => api.rest.get<any[]>('/states/'), {
    staleTime: Infinity,
    placeholderData: [],
    select: data =>
      data?.map(s =>
        Object.assign(s, {
          label: s.name,
          value: s.abbreviation,
          canService: s.canService,
          canServiceBenefit: s.canServiceBenefit,
        })
      ),
  })

export const usePreferredLanguages = () =>
  useQuery<PreferredLanguage[]>(
    'preferredLanguages',
    () => api.rest.get<PreferredLanguage[]>(`/facts/preferred-languages/`),
    {
      staleTime: Infinity,
      placeholderData: [],
    }
  )
export const usePayers = () =>
  useQuery(
    'payers',
    () => api.rest.get<InsuranceProvider[]>('/insurance/provider/', { fireflyAccepted: true }),
    {
      staleTime: Infinity,
      placeholderData: [],
      onSuccess: data => {
        for (const payer of data) {
          // This serves the same purpose as Image.prefetch for the web to preload these images
          const img = new Image()
          if (payer.logo) img.src = payer.logo
        }
      },
    }
  )

export const getURLSignature = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const signature = params.get('signature')
  if (signature) {
    return encodeURIComponent(signature)
  }
  return signature
}

export const useAccountFormNavigation = () => {
  // TODO: check duplicate account based on result navigate to Auth0 or duplicate account screen
  const history = useHistory()
  const setToken = useSetToken()
  const auth0 = useAuth0()
  return useMutation<[any], void>(async values => {
    try {
      if (
        !values.state.canService &&
        !values.insurancePayer?.name.toLowerCase().includes('firefly')
      ) {
        history.push({ pathname: '/signup/cannot-service', state: { states: values.state } })
        return
      }
      if (values.employeeIdentifier) {
        const validateSIDUrl = `/user/validate-sid/?first_name=${values.firstName}&last_name=${
          values.lastName
        }&phone_number=${values.phoneNumber}&dob=${format(
          parse(values.dob, 'MM/dd/yyyy', new Date()),
          'yyyy-MM-dd'
        )}&sex=${values.sex as Sex}&employee_identifier=${values.employeeIdentifier}`
        await api.rest.get(validateSIDUrl)
      }
      logEvent('NEW_ACCOUNT_CREATION_STARTED', {})
      if (!storedToken.get()) {
        await setToken.handler()
      }
      if (values.employer && employerField[values.employer]) {
        // history.push({
        //   pathname: '/signup/1',
        // })
        await auth0.loginWithRedirect({
          redirectUri: window.location.origin + '/signup/1',
        })
      } else {
        if (storedToken.get() && auth0.isAuthenticated && !auth0.isLoading) {
          history.push({
            pathname: '/signup/member-id',
          })
        } else {
          await auth0.loginWithRedirect({
            redirectUri: window.location.origin + '/signup/member-id',
          })
        }
      }
    } catch (e) {
      console.log(e)
      throw new Error('Error in Account Navigation')
    }
  })
}

export const GENDERS = ['Male', 'Female']
export const GENDERS_DICT = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
]

export const EMPLOYER_INSURANCE = 'employer'
export const PRIVATE_INSURANCE = 'private'
export const MEDICARE_MEDICAID = 'medicare_medicaid'
export const NO_INSURANCE = 'none'
export const NO_ACCEPTED_INSURANCE = 'no_accepted_insurance'

export const INSURANCE_SOURCE = [
  {
    label: 'Yes, through an employer',
    value: EMPLOYER_INSURANCE,
    subtext: 'My health insurance comes from an employer-sponsored plan',
  },
  {
    label: 'Yes, but not through an employer',
    value: PRIVATE_INSURANCE,
    subtext: 'I have an Affordable Care Act (ACA) or a None-ACA (Non-Qualified) plan',
  },
  {
    label: 'Yes, through Medicare or Medicaid',
    value: MEDICARE_MEDICAID,
    subtext: 'I receive Medicare or Medicaid benefits',
  },
  {
    label: "No, I don't have health insurance",
    value: NO_INSURANCE,
    subtext: 'I pay for my health care costs out of pocket',
  },
]

export const ELIGIBLE_INSURANCE = new Set([EMPLOYER_INSURANCE, PRIVATE_INSURANCE])

export const INELIGIBLE_INSURANCE_MESSAGE = {
  none: {
    title: 'Sorry. Insurance coverage is required.',
    body: 'We accept most insurance plans. Visit <a href="https://fireflyhealth.com/care">firefly.health/care</a>to learn more.',
  },
  medicare_medicaid: {
    title: 'Sorry. Firefly isn’t covered by Medicare or Medicaid yet.',
    body: null,
  },
  no_accepted_insurance: {
    title: 'Sorry. Firefly doesn’t accept any other insurance plans now but we’re working on it.',
    body: 'We accept most insurance plans. Visit <a href="https://fireflyhealth.com/care">firefly.health/care</a>to learn more.',
  },
}

export const FIREFLY_INSURANCE = 'Firefly Insurance'

export const getReferralSource = employer => {
  const employerDict = employerField[employer]
  return employerDict ? employerDict.referral : null
}

export const useBffAppSignUpMemberIdConfirmation = () => {
  const history = useHistory()
  const auth0 = useAuth0()
  return useMutation<[MemberIdConfirmationPayLoad], any>(async payload => {
    try {
      if (!payload.firstName) history.push({ pathname: '/signup/name' })
      else if (!payload.dob) history.push({ pathname: '/signup/account' })
      else if (!payload.insuranceMemberInfo.sourceType && !payload.employeeIdentifier)
        history.push({ pathname: '/signup/insurance/type' })
      else if (!payload.insuranceMemberInfo.insurancePayerId && !payload.employeeIdentifier)
        history.push({ pathname: '/signup/insurance/payer' })
      const result = await api.rest.post('/bff/app/signup/member-id-confirmation/', payload)
      switch (result.route) {
        case 'NewAccountCreationSuccessModule':
          secureCache.clear()
          storedToken.remove()
          auth0.logout({ returnTo: window.location.origin + '/success' })
          break
        case 'DuplicateAccountEducationModule':
          secureCache.clear()
          storedToken.remove()
          // The masked_email will be cleared in existing screen use Effect.
          // This value is needed to show the email in existing account screen.
          secureCache.set('masked_email', result.email)
          auth0.logout({
            returnTo: window.location.origin + '/signup/existing',
          })
          break
        case 'new-account-creation-success':
          secureCache.clear()
          storedToken.remove()
          auth0.logout({ returnTo: window.location.origin + '/success' })
          break
        case 'new-duplicate-account-screen':
          secureCache.clear()
          storedToken.remove()
          secureCache.set('masked_email', result.email)
          // The masked_email will be cleared in existing screen use Effect.
          // This value is needed to show the email in existing account screen.
          auth0.logout({
            returnTo: window.location.origin + '/signup/existing',
          })
          break
        default:
          throw new Error('User reached dead end.')
      }
    } catch (e) {
      throw new Error('Error Creating the acccount , if this keeps happening contact us.')
    }
  })
}
